import { COLORS } from 'assets/styles/colors';
import { DEVICE } from 'assets/styles/devices';
import { TYPOGRAPHY } from 'assets/styles/typography';
import { MainLayout } from 'layouts/MainLayout/MainLayout';
import {
  IUpdateDeviceState,
  UpdateDeviceStates,
} from 'pages/UpdateDevice/UpdateDevice';
import { FC, useEffect } from 'react';
import styled from 'styled-components';
import { Logo, ProgressBar } from 'ui';

interface Props extends IUpdateDeviceState {
  progress: number;
}

export const ProcessState: FC<Props> = ({ progress, changeState }) => {
  useEffect(() => {
    if (progress === 100) {
      changeState(UpdateDeviceStates.COMPLETE);
    }
  }, [progress, changeState]);

  return (
    <Root>
      <Header>
        <Logo />
      </Header>

      <Title>Устанавливаем обновление</Title>
      <Description>
        Пожалуйста, не покидайте страницу или браузерное окно
      </Description>

      <ProgressBar withPercent percentNumber={progress} />
    </Root>
  );
};

const Root = styled(MainLayout)`
  position: relative;

  .main-layout__main {
    justify-content: center;
  }

  @media ${DEVICE.mobileM} {
    padding: 20px 50px;
  }
`;

const Header = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 22px;
`;

const Title = styled.h2`
  color: ${COLORS.darkBlue};
  ${TYPOGRAPHY.buttons}
  margin-bottom: 32px;
  text-align: center;
`;

const Description = styled.p`
  color: ${COLORS.darkBlue};
  ${TYPOGRAPHY.body1}
  margin-bottom: 45px;
  text-align: center;
`;
