import EventEmitter2 from 'eventemitter2';
import { wait } from 'lib/helpers';
import {
  BluetoothConnection,
  Handler,
} from 'lib/services/bluetooth/bluetooth-connection';
import {
  Characteristics,
  uuidsByCharacteristicMap,
} from 'lib/services/constants';

enum EventTypes {
  NEW_REQUEST = 'new_request',
  END_REQUEST = 'end_request',
}

interface IQueueItem {
  command: Uint8Array;
  resolve: (...args: any[]) => void;
  reject: (...args: any[]) => void;
  withoutResponse?: boolean;
}

export class PlonqNordicConnection {
  private bluetoothConnection: BluetoothConnection;
  private rx;
  private tx;

  // Для управления очередью с помощью событий
  private emitter = new EventEmitter2();
  // Очередь для обработки не более одной команды единовременно
  private queue: IQueueItem[] = [];
  // Флаг запущенной команды
  private isBusy = false;

  constructor(bluetoothConnection: BluetoothConnection) {
    this.bluetoothConnection = bluetoothConnection;
    this.rx = this.getCharacteristic(Characteristics.NORDIC_RX);
    this.tx = this.getCharacteristic(Characteristics.NORDIC_TX);

    // Подписки на события начала и конца обработки команд
    this.emitter.on(EventTypes.NEW_REQUEST, this.handleNewRequest.bind(this));
    this.emitter.on(EventTypes.END_REQUEST, this.handleEndRequest.bind(this));
  }

  public async getPuffCount(): Promise<number> {
    const characteristic = await this.getCharacteristic(
      Characteristics.PUFF_COUNTER,
    );

    const value = await characteristic.readValue();

    return value.getUint16(0, true);
  }

  public async subscribePuffCount(handler: (puffCount: number) => void) {
    const characteristic = await this.getCharacteristic(
      Characteristics.PUFF_COUNTER,
    );

    await characteristic.startNotifications();

    const eventHandler = (event: any) => {
      if (!event.target) {
        return;
      }

      handler(event.target.value.getUint16(0, true));
    };

    characteristic.addEventListener('characteristicvaluechanged', eventHandler);
  }

  public async unsubscribePuffCount(handler: () => void) {
    const characteristic = this.getCharacteristic(Characteristics.PUFF_COUNTER);

    await characteristic.stopNotifications();
    characteristic.removeEventListener('characteristicvaluechanged', handler);
  }

  // Добавление команды в очередь
  public async sendRequest(command: Uint8Array, withoutResponse?: boolean) {
    return new Promise((resolve, reject) => {
      this.queue.push({ command, resolve, reject, withoutResponse });
      this.emitter.emit(EventTypes.NEW_REQUEST);
    });
  }

  private async handleCommand(queueItem: IQueueItem): Promise<void> {
    if (queueItem.withoutResponse) {
      await this.rx.writeValue(queueItem.command);

      // Необходимая задержка для корректной работы на мобильных устройствах
      await wait(100);

      this.emitter.emit(EventTypes.END_REQUEST);
      return queueItem.resolve();
    }

    // Для корректной работы в PLONQ браузере необходимо делать для каждой в отдельности
    const characteristic = await this.tx.startNotifications();
    // Флаг полученного ответа
    let isAnswered = false;

    // Замер времени для мониторинга производительности
    const date = Date.now();

    const eventListener = async (event: any) => {
      isAnswered = true;

      console.log('request time: ', Date.now() - date);

      characteristic.removeEventListener(
        'characteristicvaluechanged',
        eventListener,
      );

      await characteristic.stopNotifications();

      this.emitter.emit(EventTypes.END_REQUEST);
      return queueItem.resolve(event.target.value);
    };

    // Сброс команды из очереди если устройство не отвечает
    setTimeout(async () => {
      if (!isAnswered) {
        characteristic.stopNotifications();
        characteristic.removeEventListener(
          'characteristicvaluechanged',
          eventListener,
        );

        this.emitter.emit(EventTypes.END_REQUEST);
        queueItem.reject();
      }
    }, 3000);

    characteristic.addEventListener(
      'characteristicvaluechanged',
      eventListener,
    );

    await this.rx.writeValue(queueItem.command);
  }

  private async handleComplexCommand(queueItem: IQueueItem): Promise<void> {
    const characteristic = await this.tx.startNotifications();
    let isAnswered = false;
    const response: DataView[] = [];

    const eventListener = async (event: any) => {
      isAnswered = true;

      const data: number[] = [];

      for (let i = 0; i < 7; ++i) {
        data.push(event.target.value.getUint8(i));
      }

      if (data.every((data) => data === 255)) {
        console.log('stop');
        characteristic.removeEventListener(
          'characteristicvaluechanged',
          eventListener,
        );

        await characteristic.stopNotifications();
        this.emitter.emit(EventTypes.END_REQUEST);
        return queueItem.resolve(response);
      }

      console.log('get puff');
      response.push(event.target.value);
    };

    // Сброс команды из очереди если устройство не отвечает
    setTimeout(async () => {
      if (!isAnswered) {
        characteristic.stopNotifications();
        characteristic.removeEventListener(
          'characteristicvaluechanged',
          eventListener,
        );

        this.emitter.emit(EventTypes.END_REQUEST);
        queueItem.reject();
      }
    }, 3000);

    characteristic.addEventListener(
      'characteristicvaluechanged',
      eventListener,
    );

    await this.rx.writeValue(queueItem.command);
  }

  private getCharacteristic(
    characteristicName: Characteristics,
  ): BluetoothRemoteGATTCharacteristic {
    const connectionUUIDs = uuidsByCharacteristicMap[characteristicName];
    const characteristicId = connectionUUIDs[1];

    const characteristic =
      this.bluetoothConnection.getGATTCharacteristic(characteristicId);

    if (!characteristic) {
      throw new Error(`Cannot find ${characteristicName} characteristic`);
    }

    return characteristic;
  }

  private handleNewRequest() {
    if (this.isBusy) {
      return;
    }

    const command = this.queue.shift();

    if (command) {
      this.isBusy = true;

      // Проверка на команду getPuffArray
      return command.command[0] === 0xfa
        ? this.handleComplexCommand(command)
        : this.handleCommand(command);
    }
  }

  private handleEndRequest() {
    const command = this.queue.shift();

    if (command) {
      return this.handleCommand(command);
    }

    return (this.isBusy = false);
  }

  public onDisconnect(disconnectHandler: Handler): void {
    this.bluetoothConnection.onDisconnect(disconnectHandler);
  }

  public onReconnect(reconnectHandler: Handler): void {
    this.bluetoothConnection.onReconnect(() => {
      // Обновление характеристик для нового GATT сервера
      this.rx = this.getCharacteristic(Characteristics.NORDIC_RX);
      this.tx = this.getCharacteristic(Characteristics.NORDIC_TX);

      reconnectHandler();
    });
  }

  public async refreshConnection(): Promise<void> {
    await this.bluetoothConnection.refreshGattConnection();

    this.rx = this.getCharacteristic(Characteristics.NORDIC_RX);
    this.tx = this.getCharacteristic(Characteristics.NORDIC_TX);
  }
}
