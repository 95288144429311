export const API_URL = {
  baseUrl: process.env.REACT_APP_API_BASE_URL,
  signIn: '/auth/sign-in',
  signUp: '/auth/sign-up',
  phoneSendVerification: '/auth/phone-verification/send',
  phoneCheckVerification: '/auth/phone-verification/check',
  restorePasswordSendVerification: '/auth/forget-password/send',
  restorePasswordCheckVerification: '/auth/forget-password/check',
  changePassword: '/auth/forget-password',
  userProfile: '/profile',
  deviceList: '/devices/list',
  attachDevice: '/devices',
  detachDevice: (deviceId: string) => `/devices/${deviceId}`,
  getStatistics: '/stats',
  getStatisticsDaily: '/stats/daily',
  getLastSavedPuff: (deviceId: string) => `/stats/${deviceId}/last`,
  postStatistics: '/stats',
  postConnection: '/auth/connection',
  getFirstAvailableFirmware: (deviceId: string) =>
    `/firmware/${deviceId}/available`,
  syncDeviceInfo: (deviceId: string) => `/devices/${deviceId}/sync`,
  renameDevice: (deviceId: string) => `/devices/${deviceId}/rename`,
};
