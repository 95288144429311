import { ERROR_TEXTS } from 'lib/constants';
import useDeviceState from 'lib/services/plonq/hooks/useDeviceState';
import useDeviceUpdate from 'lib/services/plonq/hooks/useDeviceUpdate';
import { PlonqDevice } from 'lib/services/plonq/plonq-device';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useGetFirstAvailableFirmwareQuery } from 'store/api/firmware';
import styled from 'styled-components';
import { Spinner } from 'ui';

import { CompleteState } from './states/CompleteState';
import { FailedState } from './states/FailedState';
import { PrepareState } from './states/PrepareState';
import { ProcessState } from './states/ProcessState';

export interface IUpdateDeviceState {
  changeState(state: UpdateDeviceStates): void;
}

export enum UpdateDeviceStates {
  PREPARE = 'prepare',
  PROCESS = 'process',
  COMPLETE = 'complete',
  FAILED = 'failed',
}

export const UpdateDevice = () => {
  const { deviceId } = useParams();

  const [deviceState, setDeviceState] = useDeviceState();
  const [errorText, setErrorText] = useState<string>();

  const { updateDevice, prepareFirmwareFile, updateProgress, isFilePrepared } =
    useDeviceUpdate();

  const [state, setState] = useState<UpdateDeviceStates>(
    UpdateDeviceStates.PREPARE,
  );

  const { data: firmware, isLoading: isFirmwareLoading } =
    useGetFirstAvailableFirmwareQuery(
      { deviceId: deviceId as string },
      { skip: !deviceId },
    );

  useEffect(() => {
    if (!deviceState) {
      const deviceInstance = PlonqDevice.getInstance();
      setDeviceState(deviceInstance);

      if (!deviceInstance?.checkIsBleConnected() && !isFilePrepared) {
        setErrorText(() => ERROR_TEXTS.cantConnectToDevice);
        setState(() => UpdateDeviceStates.FAILED);
      }
    }
  }, [deviceState, setDeviceState, isFilePrepared]);

  const changeState = useCallback((state: UpdateDeviceStates) => {
    setState(() => state);
  }, []);

  if (
    ((!deviceState && !isFilePrepared) || !deviceId || isFirmwareLoading) &&
    state !== UpdateDeviceStates.FAILED
  ) {
    return (
      <SpinnerWrapper>
        <Spinner />
        <div>
          {!deviceId
            ? 'Инициализация устройства...'
            : isFirmwareLoading
            ? 'Проверяем файл прошивки...'
            : 'Соединение с устройством...'}
        </div>
      </SpinnerWrapper>
    );
  }

  const handlePrepareFirmwareFile = () => {
    if (!firmware) {
      setErrorText(ERROR_TEXTS.cantGetFirmware);
      return changeState(UpdateDeviceStates.FAILED);
    }

    return prepareFirmwareFile(firmware.downloadFileKey);
  };

  switch (state) {
    case UpdateDeviceStates.PREPARE: {
      return (
        <PrepareState
          isCharging={!!deviceState?.isCharging}
          changeState={changeState}
          prepareUpdate={handlePrepareFirmwareFile}
          updateDevice={updateDevice}
          isFirmwareFilePrepared={isFilePrepared}
          setErrorText={setErrorText}
        />
      );
    }
    case UpdateDeviceStates.PROCESS: {
      return (
        <ProcessState progress={updateProgress} changeState={changeState} />
      );
    }
    case UpdateDeviceStates.COMPLETE: {
      return <CompleteState />;
    }
    case UpdateDeviceStates.FAILED: {
      return (
        <FailedState
          state={state}
          errorText={errorText}
          changeState={changeState}
          hasDeviceConnect={!!deviceState}
        />
      );
    }
  }
};

const SpinnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: var(--100vh-height);
`;
