import { TYPOGRAPHY } from 'assets/styles/typography';
import { SearchDeviceIcon } from 'assets/svg/components';
import useDeviceLocationSignal from 'lib/services/plonq/hooks/useDeviceLocationSignal';
import { useEffect, useRef, useState } from 'react';
import { FC } from 'react';
import styled from 'styled-components';
import { Button } from 'ui/Button';
import { Modal } from 'ui/Modal';

type FindDeviceModalProps = {
  isOpen: boolean;
  closeCallback: () => void;
};

export const FindDeviceModal: FC<FindDeviceModalProps> = ({
  isOpen,
  closeCallback,
}) => {
  const sendDeviceLocationSignal = useDeviceLocationSignal();
  const [isSearchingEnabled, setIsSearchingEnabled] = useState(false);
  const intervalId = useRef<NodeJS.Timeout | undefined>();
  const FIVE_SECONDS = 1000 * 5;

  const startSearchDevice = () => {
    setIsSearchingEnabled(true);
    sendDeviceLocationSignal();

    intervalId.current = setInterval(() => {
      sendDeviceLocationSignal();
    }, FIVE_SECONDS);
  };

  const stopSearchDevice = () => {
    clearInterval(intervalId.current);
    intervalId.current = undefined;
    setIsSearchingEnabled(false);
  };

  useEffect(() => {
    return stopSearchDevice;
  }, []);

  return (
    <Modal isOpen={isOpen} closeCallback={closeCallback}>
      <Content>
        {isSearchingEnabled ? (
          <>
            <StyledSearchDeviceIcon isSearchActive />
            <Title $isFound>
              Устройство Plonq X, будет вибрировать пока вы не нажмете
              прекратить поиск.
            </Title>
            <Button onClick={stopSearchDevice} variant="transparent">
              Прекратить поиск
            </Button>
          </>
        ) : (
          <>
            <StyledSearchDeviceIcon />
            <Title>
              Нажмите <Bold>Поиск устройства</Bold>, чтобы Plonq X начал
              подавать сигнал
            </Title>
            <Button onClick={startSearchDevice} variant="primary">
              Поиск устройства
            </Button>
          </>
        )}
      </Content>
    </Modal>
  );
};

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 366px;
  border-radius: 25px;
  background-color: white;
  padding: 31px 36px 36px;
`;

const Title = styled.p<{ $isFound?: boolean }>`
  ${TYPOGRAPHY.body3}
  text-align: center;
  max-width: 259px;
  margin-bottom: 21px;

  ${({ $isFound }) =>
    $isFound &&
    `
    max-width: 281px;
  `}
`;

const Bold = styled.span`
  font-weight: 500;
`;

const StyledSearchDeviceIcon = styled(SearchDeviceIcon)`
  margin-bottom: 12px;
`;
