import {
  getOverpuffSettingsCommand,
  overpuffSettingsResponse,
  setOverpuffSettingsCommand,
} from '../bluetooth/commands';
import { IOverpuffSettings } from '../interfaces';
import { BLEConnectionNotInstalled } from './exception/ble-connection-not-installed';
import { PlonqNordicConnectedService } from './plonq-nordic-connected-service';

type UpdateHandler = (device: PlonqOverpuff) => void;

export class PlonqOverpuff extends PlonqNordicConnectedService {
  private static instance?: PlonqOverpuff;
  private settings?: IOverpuffSettings;
  private updateHandlers: UpdateHandler[] = [];

  private constructor() {
    super();
  }

  public static getInstance(): PlonqOverpuff {
    if (this.instance) {
      return this.instance;
    }

    this.instance = new PlonqOverpuff();

    return this.instance;
  }

  public getSettings(): IOverpuffSettings | undefined {
    return this.settings;
  }

  public async syncWithBLE(): Promise<void> {
    if (!this.bleConnection) {
      throw new BLEConnectionNotInstalled();
    }

    const overpuffBinary = (await this.bleConnection.sendRequest(
      getOverpuffSettingsCommand(),
    )) as DataView;

    this.bleConnection.onDisconnect(this.clearOverpuffData.bind(this));
    this.bleConnection.onReconnect(this.syncWithBLE.bind(this));

    this.settings = overpuffSettingsResponse(overpuffBinary);

    this.dispatchUpdate();
  }

  public async setSettings(newSettings: IOverpuffSettings): Promise<void> {
    if (!this.bleConnection) {
      throw new BLEConnectionNotInstalled();
    }

    newSettings.timeout = 600;
    await this.bleConnection.sendRequest(
      setOverpuffSettingsCommand(
        newSettings.isEnabled,
        newSettings.puffCount,
        newSettings.timeout,
      ),
      true,
    );

    await this.syncWithBLE();
  }

  public subscribeOnUpdates(addingHandler: UpdateHandler): void {
    this.updateHandlers.push(addingHandler);
  }

  public unsubscribeFromUpdate(removingHandler: UpdateHandler): void {
    this.updateHandlers = this.updateHandlers.filter(
      (handler) => handler !== removingHandler,
    );
  }

  private dispatchUpdate(): void {
    for (const listener of this.updateHandlers) listener(this);
  }

  private clearOverpuffData = (): void => {
    PlonqOverpuff.instance = undefined;
    this.isConnected = false;
    this.bleConnection = undefined;
    this.settings = undefined;
    this.updateHandlers = [];
  };
}
