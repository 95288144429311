import { CompareVersion } from 'lib/helpers/compareVersions';

import {
  clearPuffArrayCommand,
  getPuffArrayCommand,
  getPuffCommand,
  puffResponse,
} from '../bluetooth/commands';
import { VERSION_FRACTURE, VERSION_WITH_GPA } from '../constants';
import { IPuff } from '../interfaces';
import { BLEConnectionNotInstalled } from './exception/ble-connection-not-installed';
import { PlonqDevice } from './plonq-device';
import { PlonqNordicConnectedService } from './plonq-nordic-connected-service';

type UpdateHandler = (device: PlonqPuffArray) => void;

export class PlonqPuffArray extends PlonqNordicConnectedService {
  private puffArray: IPuff[] = [];
  private static instance?: PlonqPuffArray;
  private updateHandlers: UpdateHandler[] = [];
  private timestampOffset = 0;
  private isSynchronizing = false;

  // Для создания необходимо чтобы PlonqDevice был инициализирован
  private constructor() {
    super();

    // Необходимо для корректировки UNIX time установленного на устройстве
    this.timestampOffset = PlonqDevice.getInstance().getTimestampOffset();

    window.addEventListener(
      'visibilitychange',
      () =>
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        document.visibilityState === 'visible' && this.syncPuff.bind(this)(),
    );
  }

  public static getInstance(): PlonqPuffArray {
    if (this.instance) {
      return this.instance;
    }

    this.instance = new PlonqPuffArray();

    return this.instance;
  }

  public getPuff(index: number) {
    return this.puffArray[index];
  }

  public getPuffArray(from?: number, to?: number): IPuff[] {
    return this.puffArray.slice(from, to);
  }

  public getTimestampOffset(): number {
    return this.timestampOffset;
  }

  public async clearPuffArray(): Promise<void> {
    if (!this.bleConnection) {
      throw new BLEConnectionNotInstalled();
    }

    this.bleConnection.sendRequest(clearPuffArrayCommand(), true);

    this.puffArray = [];
    this.dispatchUpdate();
  }

  private async updatePuffArray(puffCount: number): Promise<void> {
    if (!this.bleConnection) {
      throw new BLEConnectionNotInstalled();
    }

    // Необходимо для игнорирования затяжек на время синхронизации
    if (this.isSynchronizing) {
      return;
    }

    if (puffCount === 0) {
      return;
    }

    const puffBinary = await this.bleConnection.sendRequest(
      getPuffCommand(puffCount),
    );

    const puff = puffResponse(puffBinary as DataView);
    this.puffArray.unshift({
      ...puff,
      timestamp: puff.timestamp + this.timestampOffset,
    });

    this.dispatchUpdate();
  }

  private dispatchUpdate(): void {
    for (const listener of this.updateHandlers) listener(this);
  }

  public subscribeOnUpdates(addingHandler: UpdateHandler): void {
    this.updateHandlers.push(addingHandler);
  }

  public unsubscribeFromUpdate(removingHandler: UpdateHandler): void {
    this.updateHandlers = this.updateHandlers.filter(
      (handler) => handler !== removingHandler,
    );
  }

  private clearPuffArrayData = (): void => {
    PlonqPuffArray.instance = undefined;
    this.isConnected = false;
    this.bleConnection = undefined;
    this.puffArray = [];
    this.updateHandlers = [];
    this.timestampOffset = 0;
  };

  public async syncWithBLE(): Promise<void> {
    console.log('Sync start');
    if (!this.bleConnection) {
      throw new BLEConnectionNotInstalled();
    }

    await this.syncPuff();

    this.bleConnection.onDisconnect(this.clearPuffArrayData.bind(this));
    this.bleConnection.onReconnect(this.subscribeChanges.bind(this));

    await this.subscribeChanges();
    console.log('Sync end');
  }

  private async syncPuff(): Promise<void> {
    if (!this.bleConnection) {
      throw new BLEConnectionNotInstalled();
    }

    this.isSynchronizing = true;
    const puffCount = await this.bleConnection.getPuffCount();

    console.log('puff count: ', puffCount);

    if (
      // Проверка на наличие GPA
      CompareVersion(PlonqDevice.getInstance().getFirmware(), VERSION_FRACTURE)
    ) {
      console.log('skip sync. (cause: firmware version)');
      return;
    }

    if (
      // Проверка на наличие GPA
      CompareVersion(PlonqDevice.getInstance().getFirmware(), VERSION_WITH_GPA)
    ) {
      await this.syncPuffSeparately(puffCount);
    } else {
      await this.syncPuffWithGPA(puffCount);
    }

    this.dispatchUpdate();
    this.isSynchronizing = false;
  }

  // Синхронизация для getPuffArray
  private async syncPuffWithGPA(puffCount: number) {
    console.log('Sync with GPA');

    if (!this.bleConnection) {
      throw new BLEConnectionNotInstalled();
    }

    if (puffCount <= 0) {
      return;
    }

    const puffBinaries = (await this.bleConnection.sendRequest(
      getPuffArrayCommand(1, puffCount),
    )) as DataView[];

    this.puffArray = puffBinaries.map((puffBinary) => {
      const puff = puffResponse(puffBinary);

      return {
        ...puff,
        timestamp: puff.timestamp + this.timestampOffset,
      };
    });

    console.log(this.puffArray.length);
  }

  // Синхронизация по-штучно
  private async syncPuffSeparately(puffCount: number) {
    console.log('Sync without GPA');

    if (!this.bleConnection) {
      throw new BLEConnectionNotInstalled();
    }

    for (let i = 1; i <= puffCount; ++i) {
      // Необходимо для сброса тротлинга GATT сервера.
      if (i % 50 === 0) {
        await this.refreshConnection();
      }

      console.log('puff number: ', i);
      const puffBinary = await this.bleConnection.sendRequest(
        getPuffCommand(i),
      );

      const puff = puffResponse(puffBinary as DataView);

      this.puffArray.unshift({
        ...puff,
        timestamp: puff.timestamp + this.timestampOffset,
      });
    }
  }

  // Подписка на изменение количества затяжек
  private async subscribeChanges(): Promise<void> {
    if (!this.bleConnection) {
      throw new BLEConnectionNotInstalled();
    }

    await this.bleConnection.subscribePuffCount(
      this.updatePuffArray.bind(this),
    );
  }
}
