import { ReactComponent as BackArrowIcon } from 'assets/svg/arrow-back.svg';
import { ReactComponent as ArrowDeclineIcon } from 'assets/svg/arrow-decline.svg';
import { ReactComponent as BatteryChargingIcon } from 'assets/svg/battery-charging.svg';
import { ReactComponent as BatteryFullIcon } from 'assets/svg/battery-full.svg';
import { ReactComponent as BurgerIcon } from 'assets/svg/burger.svg';
import { ReactComponent as ButtonsSpinnerIcon } from 'assets/svg/buttons-spinner.svg';
import { ReactComponent as CalendarIcon } from 'assets/svg/calendar.svg';
import { ReactComponent as CallIcon } from 'assets/svg/call-icon.svg';
import { ReactComponent as CantConnectIcon } from 'assets/svg/cant-connect-icon.svg';
import { ReactComponent as CheckInCircleIcon2 } from 'assets/svg/check-icon-in-circle-2.svg';
import { ReactComponent as CheckInCircleIcon } from 'assets/svg/check-icon-in-circle.svg';
import { ReactComponent as CheckLineIcon } from 'assets/svg/check-line.svg';
import { ReactComponent as Close } from 'assets/svg/close.svg';
import { ReactComponent as ConeBigIcon } from 'assets/svg/cone-big.svg';
import { ReactComponent as ConeIcon } from 'assets/svg/cone.svg';
import { ReactComponent as DeviceIcon } from 'assets/svg/device-icon.svg';
import { ReactComponent as EditWithLine } from 'assets/svg/edit-with-line.svg';
import { ReactComponent as EyeClosedIcon } from 'assets/svg/eye-closed.svg';
import { ReactComponent as EyeOpenIcon } from 'assets/svg/eye-open.svg';
import { ReactComponent as FindButtonIcon } from 'assets/svg/find-button-icon.svg';
import { ReactComponent as FlavorIcon } from 'assets/svg/flavor.svg';
import { ReactComponent as LockIcon } from 'assets/svg/lock.svg';
import { ReactComponent as LogoIcon } from 'assets/svg/logo.svg';
import { ReactComponent as LogOutIcon } from 'assets/svg/logout-icon.svg';
import { ReactComponent as NicotineIcon } from 'assets/svg/nicotine.svg';
import { ReactComponent as PlusIcon } from 'assets/svg/plus-icon.svg';
import { ReactComponent as ProfileIcon } from 'assets/svg/profile.svg';
import { ReactComponent as PuffsIcon } from 'assets/svg/puffs.svg';
import { ReactComponent as QuestionCloseIcon } from 'assets/svg/question-close.svg';
import { ReactComponent as QuestionMarkIcon } from 'assets/svg/question-mark.svg';
import { ReactComponent as SignalIcon } from 'assets/svg/signal.svg';
import { ReactComponent as SiteIcon } from 'assets/svg/site-icon.svg';
import { ReactComponent as SpinnerIcon } from 'assets/svg/spinner.svg';
import { ReactComponent as StoreIcon } from 'assets/svg/store-icon.svg';
import { ReactComponent as SupportIcon } from 'assets/svg/support-icon.svg';
import { ReactComponent as ToStatsArrowIcon } from 'assets/svg/to-stats-arrow-icon.svg';
import { ReactComponent as UpdateSoftwareIcon } from 'assets/svg/update-software.svg';

const Icons = {
  eyeOpen: EyeOpenIcon,
  eyeClosed: EyeClosedIcon,
  editWithLine: EditWithLine,
  call: CallIcon,
  logo: LogoIcon,
  lock: LockIcon,
  profile: ProfileIcon,
  calendar: CalendarIcon,
  buttonLoader: ButtonsSpinnerIcon,
  spinner: SpinnerIcon,
  backArrow: BackArrowIcon,
  checkline: CheckLineIcon,
  checkInCircle: CheckInCircleIcon,
  checkInCircle2: CheckInCircleIcon2,
  close: Close,
  questionMark: QuestionMarkIcon,
  arrowDecline: ArrowDeclineIcon,
  updateSoftware: UpdateSoftwareIcon,
  device: DeviceIcon,
  logout: LogOutIcon,
  nicotine: NicotineIcon,
  site: SiteIcon,
  store: StoreIcon,
  support: SupportIcon,
  burger: BurgerIcon,
  signal: SignalIcon,
  batteryCharging: BatteryChargingIcon,
  batteryFull: BatteryFullIcon,
  flavor: FlavorIcon,
  findButton: FindButtonIcon,
  plus: PlusIcon,
  puffs: PuffsIcon,
  cantConnect: CantConnectIcon,
  toStatsArrow: ToStatsArrowIcon,
  cone: ConeIcon,
  coneBig: ConeBigIcon,
  questionClose: QuestionCloseIcon,
} as const;

interface IconProps {
  type: keyof typeof Icons;
  [x: string | number | symbol]: unknown;
}

export const Icon = ({ type, ...props }: IconProps) => {
  const IconComponent = Icons[type];
  return <IconComponent {...props} />;
};
