import { COLORS } from 'assets/styles/colors';
import { TYPOGRAPHY } from 'assets/styles/typography';
import { EditLimit, EditLimitFormValues } from 'components';
import { OVERPUFF_TIMEOUT } from 'lib/constants';
import { useOnClickOutside } from 'lib/hooks';
import useDeviceOverpuffState from 'lib/services/plonq/hooks/useDeviceOverpuffState';
import { FC, useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import styled from 'styled-components';
import { Icon, Switch } from 'ui';

type FormValues = {
  isEnabled?: boolean;
};

export interface OverPuffControlProps {
  className?: string;
}

export const OverPuffControl: FC<OverPuffControlProps> = ({ className }) => {
  const [isOpenEditLimit, setIsOpenEditLimit] = useState(false);
  const overPuffControlWrapperRef = useRef(null);
  const [overPuff, { setSettings: setOverPuffSettings }] =
    useDeviceOverpuffState();
  const { setValue, control } = useForm<FormValues>();
  useOnClickOutside(overPuffControlWrapperRef, () => setIsOpenEditLimit(false));

  useEffect(() => {
    setValue('isEnabled', overPuff?.isEnabled);
  }, [overPuff?.isEnabled]);

  const onSubmitEditLimit = (data: EditLimitFormValues) => {
    if (!overPuff) return;

    return setOverPuffSettings({
      isEnabled: overPuff.isEnabled,
      timeout: OVERPUFF_TIMEOUT,
      puffCount: data.limit,
    });
  };

  return (
    /* eslint-disable no-constant-condition */
    <Root className={className} ref={overPuffControlWrapperRef}>
      {overPuff ? (
        <>
          <Controller
            control={control}
            name="isEnabled"
            render={({ field: { onChange, value } }) => {
              return (
                <StyledSwitch
                  label="Контроль потребления никотина"
                  onChange={(event) => {
                    onChange(event);

                    const isEnabled = event.target.checked;

                    setOverPuffSettings({
                      isEnabled,
                      timeout: OVERPUFF_TIMEOUT,
                      puffCount: overPuff.puffCount,
                    });
                  }}
                  checked={value}
                />
              );
            }}
          />
          <button onClick={() => setIsOpenEditLimit(!isOpenEditLimit)}>
            <Icon type="questionMark" />
          </button>
          {isOpenEditLimit && (
            <EditLimitWrapper>
              <EditLimit
                onClose={() => setIsOpenEditLimit(!isOpenEditLimit)}
                onEdit={onSubmitEditLimit}
                limit={overPuff.puffCount}
              />
            </EditLimitWrapper>
          )}
        </>
      ) : (
        <LoadingText>Загрузка...</LoadingText>
      )}
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
`;

const StyledSwitch = styled(Switch)`
  flex: 1 1 auto;
`;

const EditLimitWrapper = styled.div`
  position: absolute;
  top: calc(100% + 8px);
  left: 0;
`;

const LoadingText = styled.span`
  ${TYPOGRAPHY.body1}
  color: ${COLORS.black};
`;
