import { IPuff } from 'lib/services/interfaces';
import { useCallback, useEffect, useState } from 'react';

import { BLEConnectionNotInstalled } from '../exception/ble-connection-not-installed';
import { PlonqPuffArray } from '../plonq-puff-array';

const usePuffArrayState = () => {
  const [puffArrayRef, setPuffArrayRef] = useState<PlonqPuffArray>();
  const [puffArray, setPuffArray] = useState<IPuff[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const initPuffArrayConnection = async () => {
    setIsLoading(() => true);
    const puffArray = PlonqPuffArray.getInstance();

    await puffArray.syncWithBLE();

    setPuffArrayRef(puffArray);
    setPuffArray(puffArray.getPuffArray());
    setIsLoading(() => false);
  };

  const onPlonqPuffArrayUpdate = useCallback(
    (plonqPuffArray: PlonqPuffArray) => {
      setPuffArray(() => [...plonqPuffArray.getPuffArray()]);
    },
    [],
  );

  useEffect(() => {
    initPuffArrayConnection();
  }, []);

  useEffect(() => {
    if (puffArrayRef) {
      puffArrayRef.subscribeOnUpdates(onPlonqPuffArrayUpdate);
    }

    return () => {
      puffArrayRef?.unsubscribeFromUpdate(onPlonqPuffArrayUpdate);
    };
  }, [puffArrayRef, onPlonqPuffArrayUpdate]);

  const clearPuffArray = async () => {
    if (!puffArrayRef) {
      throw new BLEConnectionNotInstalled();
    }

    await puffArrayRef.clearPuffArray();
  };

  return [puffArray, { clearPuffArray, isLoading }] as const;
};

export default usePuffArrayState;
