import { useAuth } from 'lib/hooks';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

import { ROUTES } from './route-paths';

export function PrivateOutlet() {
  const token = useAuth();
  const location = useLocation();

  return token ? (
    <Outlet />
  ) : (
    <Navigate to={ROUTES.INTRO} state={{ from: location }} />
  );
}
