import { wait } from 'lib/helpers';
import {
  BluetoothConnection,
  Handler,
} from 'lib/services/bluetooth/bluetooth-connection';
import {
  Characteristics,
  uuidsByCharacteristicMap,
} from 'lib/services/constants';

export class PlonqDeviceConnection {
  private bluetoothConnection: BluetoothConnection;

  constructor(bluetoothConnection: BluetoothConnection) {
    this.bluetoothConnection = bluetoothConnection;
  }

  public getBluetoothConnection(): BluetoothConnection {
    return this.bluetoothConnection;
  }

  public async getFirmwareVersion(): Promise<string> {
    const characteristic = await this.getCharacteristic(
      Characteristics.FIRMWARE_VERSION,
    );
    const value = await characteristic.readValue();

    return this.parseDataToString(value);
  }

  public async getHardwareVersion(): Promise<string> {
    const characteristic = await this.getCharacteristic(
      Characteristics.HARDWARE_VERSION,
    );
    const value = await characteristic.readValue();

    return this.parseDataToString(value);
  }

  public async getModelName(): Promise<string> {
    const modelNameCharacteristic = await this.getCharacteristic(
      Characteristics.MODEL_NAME,
    );
    const manufacturerNameCharacteristic = await this.getCharacteristic(
      Characteristics.MANUFACTURER_NAME,
    );
    const modelNameValue = await modelNameCharacteristic.readValue();
    const manufactureNameValue =
      await manufacturerNameCharacteristic.readValue();

    const manufactureName = this.parseDataToString(manufactureNameValue);
    const modelName = this.parseDataToString(modelNameValue);

    return `${manufactureName} ${modelName}`;
  }

  public async getBatteryLevel(): Promise<number> {
    const characteristic = await this.getCharacteristic(
      Characteristics.BATTERY_LEVEL,
    );
    const value = await characteristic.readValue();

    return value.getUint8(0);
  }

  public async getIsCharging(): Promise<boolean> {
    const characteristic = await this.getCharacteristic(
      Characteristics.CHARGING,
    );
    const value = await characteristic.readValue();

    return value.getUint8(0) === 1;
  }

  public async getMacAddress(): Promise<string> {
    const characteristic = await this.getCharacteristic(
      Characteristics.MAC_ADDRESS,
    );
    const value = await characteristic.readValue();

    const macArr = Array(6).fill(undefined);

    for (let i = 0; i < macArr.length; ++i) {
      const macPartStr = value.getUint8(i).toString(16);
      macArr[i] = macPartStr.length > 1 ? macPartStr : '0' + macPartStr;
    }

    return macArr.join(':').toLowerCase();
  }

  public async getCartridgeId(): Promise<number> {
    const characteristic = await this.getCharacteristic(
      Characteristics.CARTRIDGE_ID,
    );
    const value = await characteristic.readValue();

    return value.getUint8(0);
  }

  public async getCartridgeResistance(): Promise<number> {
    const characteristic = await this.getCharacteristic(
      Characteristics.CARTRIDGE_RESISTANCE,
    );
    const value = await characteristic.readValue();

    return value.getUint32(0, true);
  }

  public async getUnixTime(): Promise<number> {
    const characteristic = await this.getCharacteristic(
      Characteristics.UNIX_TIME,
    );
    const value = await characteristic.readValue();

    return value.getUint32(0, true);
  }

  public async getRSSI(): Promise<number> {
    const characteristic = await this.getCharacteristic(Characteristics.RSSI);
    const value = await characteristic.readValue();

    return value.getInt8(0);
  }

  public async onCartridgeIdChange(
    handler: (id: number) => void,
  ): Promise<void> {
    // Необходимо для корректной работы на мобильных устройствах
    await wait(100);
    let characteristic = await this.getCharacteristic(
      Characteristics.CARTRIDGE_ID,
    );

    characteristic = await characteristic.startNotifications();
    characteristic.addEventListener(
      'characteristicvaluechanged',
      (event: any) => {
        if (event.target) {
          handler(event.target.value.getUint8(0));
        }
      },
    );
  }

  public async onChargingChange(handler: (id: boolean) => void): Promise<void> {
    // Необходимо для корректной работы на мобильных устройствах
    await wait(100);
    let characteristic = this.getCharacteristic(Characteristics.CHARGING);

    characteristic = await characteristic.startNotifications();
    characteristic.addEventListener(
      'characteristicvaluechanged',
      (event: any) => {
        if (event.target) {
          handler(event.target.value.getUint8(0) === 1);
        }
      },
    );
  }

  public async onBatteryLevelChange(
    handler: (id: number) => void,
  ): Promise<void> {
    let characteristic = this.getCharacteristic(Characteristics.BATTERY_LEVEL);

    characteristic = await characteristic.startNotifications();
    characteristic.addEventListener(
      'characteristicvaluechanged',
      (event: any) => {
        if (event.target) {
          handler(event.target.value.getUint8(0));
        }
      },
    );
  }

  public async onRSSIChange(handler: (id: number) => void): Promise<void> {
    // Необходимо для корректной работы на мобильных устройствах
    await wait(100);
    let characteristic = this.getCharacteristic(Characteristics.RSSI);

    characteristic = await characteristic.startNotifications();
    characteristic.addEventListener(
      'characteristicvaluechanged',
      (event: any) => {
        if (event.target) {
          handler(event.target.value.getInt8(0));
        }
      },
    );
  }

  private getCharacteristic(
    characteristicName: Characteristics,
  ): BluetoothRemoteGATTCharacteristic {
    const connectionUUIDs = uuidsByCharacteristicMap[characteristicName];
    const characteristicId = connectionUUIDs[1];

    const characteristic =
      this.bluetoothConnection.getGATTCharacteristic(characteristicId);

    if (!characteristic) {
      throw new Error(`Cannot find ${characteristicName} characteristic`);
    }

    return characteristic;
  }

  public disconnect = (): void => {
    this.bluetoothConnection.getGATTGateway().disconnectGattServer();
  };

  private parseDataToString(buffer: DataView): string {
    let string = '';
    for (let i = 0; i < buffer.byteLength; ++i) {
      const a = buffer.getUint8(i);

      string += String.fromCharCode(a);
    }

    return string;
  }

  public onDisconnect(disconnectHandler: Handler): void {
    this.bluetoothConnection.onDisconnect(disconnectHandler);
  }

  public onReconnect(reconnectHandler: Handler): void {
    this.bluetoothConnection.onReconnect(reconnectHandler);
  }
}
