const storage = window.localStorage;

class StorageService {
  set = (key: string, value: string): void => {
    storage.setItem(key, JSON.stringify(value));
  };

  get = <T>(key: string): T | null => {
    const value = storage.getItem(key);
    return value ? JSON.parse(value) : null;
  };
}

export default new StorageService();
