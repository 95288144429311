import { BLEConnectionNotInstalled } from './exception/ble-connection-not-installed';
import { PlonqDevice } from './plonq-device';
import { PlonqNordicConnection } from './plonq-nordic-connection';

export abstract class PlonqNordicConnectedService {
  protected bleConnection?: PlonqNordicConnection;
  protected isConnected = false;

  protected constructor() {
    const bluetoothConnection = PlonqDevice.getInstance()
      .getBleConnection()
      ?.getBluetoothConnection();

    if (!bluetoothConnection) {
      throw new BLEConnectionNotInstalled();
    }

    this.bleConnection = new PlonqNordicConnection(bluetoothConnection);
    this.isConnected = true;
  }

  public checkIsConnected(): boolean {
    return this.isConnected;
  }

  public setBleConnection(bleConnection: PlonqNordicConnection): void {
    this.bleConnection = bleConnection;
    this.isConnected = true;
  }

  public getBleConnection(): PlonqNordicConnection | undefined {
    return this.bleConnection;
  }

  protected async refreshConnection(): Promise<void> {
    return this.bleConnection?.refreshConnection();
  }
}
