import { BluetoothConnection } from 'lib/services/bluetooth/bluetooth-connection';
import {
  BATTERY_INFORMATION_GATT_SERVICE_UUID,
  DEVICE_INFORMATION_GATT_SERVICE_UUID,
  DEVICE_NAME_PREFIX,
  NORDIC_GATT_SERVICE_UUID,
  PLONQ_GATT_SERVICE_UUID,
} from 'lib/services/constants';
import { useCallback } from 'react';

const useDeviceRequest = () => {
  const requestDevices = useCallback(async () => {
    const bluetoothConnection = new BluetoothConnection();
    const device = await bluetoothConnection.requestDevice(DEVICE_NAME_PREFIX, [
      DEVICE_INFORMATION_GATT_SERVICE_UUID,
      BATTERY_INFORMATION_GATT_SERVICE_UUID,
      PLONQ_GATT_SERVICE_UUID,
      NORDIC_GATT_SERVICE_UUID,
    ]);

    return device;
  }, []);

  return requestDevices;
};

export default useDeviceRequest;
