import { COLORS } from 'assets/styles/colors';
import { TYPOGRAPHY } from 'assets/styles/typography';
import { FC, useId, useState } from 'react';
import { useForm } from 'react-hook-form';
import styled, { css } from 'styled-components';
import { Icon } from 'ui';

export interface EditLimitFormValues {
  limit: number;
}

export interface EditLimitProps {
  onClose: () => void;
  onEdit: (data: EditLimitFormValues) => Promise<void> | undefined;
  className?: string;
  limit: number;
}

export const EditLimit: FC<EditLimitProps> = ({
  className,
  onClose,
  onEdit,
  limit,
}) => {
  const id = useId();
  const [isEditable, setIsEditable] = useState(false);
  const { handleSubmit, register, reset } = useForm<EditLimitFormValues>();

  const onSubmit = async (data: EditLimitFormValues) => {
    await onEdit(data);
    setIsEditable(false);
  };

  return (
    <Root className={className}>
      <CloseBtn onClick={onClose}>
        <Icon type="close" />
      </CloseBtn>
      <Title>
        Данная функция позволяет включать и выключать режим контроля затяжек.
      </Title>
      <Description>
        Если контроль потребления никотина включен, Plonq X начнет вибрировать
        после достижения лимита затяжек.
      </Description>
      <Form>
        <Label htmlFor={id}>Лимит затяжек за 10 минут:</Label>
        <InputWrapper $isEditable={isEditable}>
          {!isEditable ? (
            <>
              <Limit>{limit}</Limit>
              <button
                type="button"
                onClick={() => {
                  setIsEditable(!isEditable);
                }}
              >
                <Icon type="editWithLine" />
              </button>
            </>
          ) : (
            <>
              <Input
                autoFocus
                type="text"
                inputMode="numeric"
                {...register('limit', {
                  valueAsNumber: true,
                  validate: (value) => value > 0,
                })}
                id={id}
              />
              <InputButtons>
                <SubmitBtn onClick={handleSubmit(onSubmit)}>
                  <Icon type="checkInCircle2" />
                </SubmitBtn>
                <CloseEditBtn
                  type="button"
                  onClick={() => {
                    setIsEditable(!isEditable);
                    reset();
                  }}
                >
                  <Icon type="questionClose" />
                </CloseEditBtn>
              </InputButtons>
            </>
          )}
        </InputWrapper>
      </Form>
    </Root>
  );
};

interface InputWrapperStyleProps {
  $isEditable?: boolean;
}

const Root = styled.div`
  width: 100%;
  max-width: 348px;
  padding: 18px 42px 11px 42px;
  background-color: ${COLORS.white};
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  border: 1px solid ${COLORS.lightBlue};
`;

const Title = styled.h4`
  ${TYPOGRAPHY.subline1};
  color: ${COLORS.black};
  text-align: center;
  margin-bottom: 10px;
  max-width: 265px;
`;

const Description = styled.p`
  ${TYPOGRAPHY.subline1};
  color: ${COLORS.black};
  text-align: center;
  max-width: 270px;
  margin-bottom: 10px;
`;

const CloseBtn = styled.button`
  position: absolute;
  top: 7px;
  right: 7px;
`;

const Form = styled.form`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  max-width: 263px;
`;

const Label = styled.label`
  ${TYPOGRAPHY.subline4}
  color: ${COLORS.black};
  margin-right: 10px;
`;

const InputWrapper = styled.div<InputWrapperStyleProps>`
  border-radius: 24px;
  display: flex;
  align-items: center;
  padding: 4px 12px;
  position: relative;

  ${({ $isEditable }) =>
    $isEditable
      ? css`
          border: 1px solid ${COLORS.lightViolet};
          background-color: ${COLORS.white};
          width: 100px;
          max-width: 107px;
        `
      : css`
          background-color: ${COLORS.lightViolet};
        `};
`;

const Limit = styled.span`
  ${TYPOGRAPHY.body1}
  color: ${COLORS.black};
  margin-right: 6px;
`;

const Input = styled.input`
  flex: 0 1 25px;
  width: 100%;
  ${TYPOGRAPHY.body1}
  color: ${COLORS.black};
`;

const InputButtons = styled.div`
  position: absolute;
  top: -3px;
  right: 0;
`;

const SubmitBtn = styled.button`
  position: relative;
  left: 7px;
`;

const CloseEditBtn = styled.button`
  svg {
    stroke: ${COLORS.red};
  }
`;
