export const getPuffArrayCommand = (from: number, to: number) => {
  if (from > to) {
    throw new Error(
      'from index must be lesser than to index while getting puff array',
    );
  }

  const [fromMSB, fromLSB] = toHexArray(from, 2);
  const [toMSB, toLSB] = toHexArray(to, 2);

  return new Uint8Array([
    0xfa,
    Number.parseInt(fromMSB),
    Number.parseInt(fromLSB),
    Number.parseInt(toMSB),
    Number.parseInt(toLSB),
    0xfa,
  ]);
};

export const getOverpuffSettingsCommand = () => {
  return new Uint8Array([0x80, 0x99]);
};

export const getPuffCommand = (index: number) => {
  const [MSB, LSB] = toHexArray(index, 2);

  return new Uint8Array([
    0xfd,
    Number.parseInt(MSB),
    Number.parseInt(LSB),
    0xfd,
  ]);
};

export const clearPuffArrayCommand = () => {
  return new Uint8Array([0xda]);
};

export const resetCommand = (force = false) => {
  return force ? new Uint8Array([0x33]) : new Uint8Array([0x34]);
};

export const rebootCommand = (force = false) => {
  return force ? new Uint8Array([0x44]) : new Uint8Array([0x45]);
};

export const bootloaderCommand = (force = false) => {
  return force ? new Uint8Array([0x56]) : new Uint8Array([0x55]);
};

export const locationCommand = () => {
  return new Uint8Array([0xaa]);
};

export const setOverpuffSettingsCommand = (
  isEnabled: boolean,
  puffCount: number,
  timeout = 10000,
) => {
  const [pcMSB, pcLSB] = toHexArray(puffCount, 2);
  const [tMSB, t1, t2, tLSB] = toHexArray(timeout, 4);
  const enabledHex = isEnabled ? 0x01 : 0x00;

  return new Uint8Array([
    0x99,
    enabledHex,
    Number.parseInt(tMSB),
    Number.parseInt(t1),
    Number.parseInt(t2),
    Number.parseInt(tLSB),
    Number.parseInt(pcMSB),
    Number.parseInt(pcLSB),
  ]);
};

export const overpuffSettingsResponse = (dataView: DataView) => {
  const bytes = new Array(dataView.byteLength).fill(undefined);

  for (let i = 0; i < dataView.byteLength; ++i) {
    bytes[i] = toHexCode(dataView.getUint8(i));
  }

  const isEnabled = Number.parseInt(bytes[0]) == 0x01;

  const timeout = Number.parseInt(mergeHexCodes(bytes.slice(1, 5), false));

  const puffCount = Number.parseInt(mergeHexCodes(bytes.slice(5, 7), false));

  return {
    isEnabled,
    timeout,
    puffCount,
  };
};

export const puffResponse = (dataView: DataView) => {
  // TODO
  const temp = 0.02792867573;

  const bytes = new Array(dataView.byteLength).fill(undefined);

  for (let i = 0; i < dataView.byteLength; ++i) {
    bytes[i] = toHexCode(dataView.getUint8(i));
  }

  const timestamp =
    Number.parseInt(mergeHexCodes(bytes.slice(0, 4), true)) * 1000;
  const cartridgeId = Number.parseInt(bytes[4]);
  const puffTime = Number.parseInt(mergeHexCodes(bytes.slice(5), true));

  return {
    timestamp,
    cartridgeId,
    puffTime,
    nicotineLevel: (puffTime / 1000) * temp,
  };
};

const mergeHexCodes = (hex: string[], isNeedReverse = true) => {
  if (!isNeedReverse) {
    return '0x' + hex.map((hex) => hex.slice(2)).join('');
  } else {
    return (
      '0x' +
      hex
        .map((hex) => hex.slice(2))
        .reverse()
        .join('')
    );
  }
};

const toHexCode = (value: number, byteLength = 1): string => {
  return '0x' + value.toString(16).padStart(2 * byteLength, '0');
};

const toHexArray = (value: number, byteLength = 1): string[] => {
  const hexArr: string[] = [];
  const hexString = toHexCode(value, byteLength).slice(2);

  for (let i = 0; i < hexString.length; i += 2) {
    hexArr.push('0x' + hexString.slice(i, i + 2));
  }

  return hexArr;
};
