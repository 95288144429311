import { IDevice } from 'lib/services/interfaces';
import throttle from 'lodash/throttle';
import { useCallback, useEffect, useState } from 'react';

import { PlonqDevice } from '../plonq-device';

const useDeviceState = () => {
  const [deviceRef, setDeviceRef] = useState<PlonqDevice>();
  const [deviceState, setDeviceState] = useState<IDevice | undefined>();

  const deviceToState = (device: PlonqDevice) => {
    const isBleConnected = device.checkIsBleConnected();

    if (isBleConnected) {
      return {
        name: device.getName(),
        modelName: device.getModelName(),
        macAddress: device.getMacAddress(),
        cartridgeId: device.getCartridgeId(),
        firmwareVersion: device.getFirmware(),
        hardwareVersion: device.getHardwareVersion(),
        batteryLevel: device.getBatteryLevel(),
        isCharging: device.getIsCharging(),
        rssi: device.getRSSI(),
      };
    }
  };

  const onDeviceUpdate = useCallback((device: PlonqDevice) => {
    setDeviceState(() => deviceToState(device));
  }, []);

  const throttledDeviceUpdate = useCallback(throttle(onDeviceUpdate, 3000), []);

  const setDevice = (device?: PlonqDevice) => {
    setDeviceRef(device);
  };

  useEffect(() => {
    if (deviceRef) {
      setDeviceState(() => deviceToState(deviceRef));
      deviceRef.subscribeOnUpdates(throttledDeviceUpdate);
    }

    return () => {
      deviceRef?.unsubscribeFromUpdate(throttledDeviceUpdate);
    };
  }, [deviceRef, throttledDeviceUpdate]);

  return [deviceState, setDevice] as const;
};

export default useDeviceState;
