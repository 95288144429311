import { useAuth } from 'lib/hooks';
import useDeviceConnect from 'lib/services/plonq/hooks/useDeviceConnect';
import useDeviceRequest from 'lib/services/plonq/hooks/useDeviceRequest';
import useDeviceState from 'lib/services/plonq/hooks/useDeviceState';
import { useState } from 'react';
import {
  useAttachDeviceMutation,
  useDetachDeviceMutation,
  useGetDeviceListQuery,
} from 'store/api/devices';
import styled from 'styled-components';
import { Button } from 'ui';

import DeviceConnected from './components/DeviceConnected';
import DeviceToConnect from './components/DeviceToConnect';
import PuffArray from './components/PuffArray';

// 6be7e97d-b60f-472f-a8ee-514fef8a75a1

export const Example = () => {
  const requestDevice = useDeviceRequest();
  const connectDevice = useDeviceConnect();
  const isAuth = useAuth();

  const [attachDevice, { isLoading: isDeviceAttaching }] =
    useAttachDeviceMutation();

  const [detachDevice, { isLoading: isDeviceDetaching }] =
    useDetachDeviceMutation();

  const {
    data,
    isLoading: isDeviceListLoading,
    refetch,
  } = useGetDeviceListQuery();

  const [errorText, setErrorText] = useState<string | undefined>();

  const [deviceState, setDeviceState] = useDeviceState();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleDeviceRequest = async () => {
    setIsLoading(() => true);
    const device = await requestDevice();
    await setDeviceState(device);
    setIsLoading(() => false);
  };

  const handleDeviceConnect = async (deviceName: string) => {
    setIsLoading(() => true);
    const device = await connectDevice(deviceName);
    await setDeviceState(device);
    setIsLoading(() => false);
  };

  const handleAttachDevice = async () => {
    try {
      if (!deviceState) {
        return setErrorText('Устройство не подключено');
      }

      if (!isAuth) {
        return setErrorText('Пользователь не авторизован');
      }

      await attachDevice({
        name: deviceState.name,
        modelName: deviceState.modelName,
        macAddress: deviceState.macAddress,
        firmwareVersion: deviceState.firmwareVersion,
        hardwareVersion: deviceState.hardwareVersion,
      });

      refetch();
    } catch (err: any) {
      setErrorText(err.message);
    }
  };

  const handleDetachDevice = async (deviceId: string) => {
    try {
      if (!isAuth) {
        return setErrorText('Пользователь не авторизован');
      }

      await detachDevice({ deviceId });
      refetch();
    } catch (err: any) {
      setErrorText(err.message);
    }
  };

  const isPageLoading =
    isDeviceListLoading || isDeviceAttaching || isDeviceDetaching || isLoading;

  return (
    <>
      <StyledContainer>
        <Button
          variant="primary"
          onClick={handleDeviceRequest}
          isLoading={isPageLoading}
        >
          Search new
        </Button>
      </StyledContainer>
      {errorText}
      <StyledDeviceContainer>
        <StyledDeviceListContainer>
          {data &&
            data.devices.map((device) => (
              <DeviceToConnect
                key={device.id}
                device={device}
                onConnect={handleDeviceConnect}
                onRemove={handleDetachDevice}
                isLoading={isPageLoading}
              />
            ))}
        </StyledDeviceListContainer>
        <DeviceConnectContainer>
          {isLoading && <div>isLoading</div>}
          {deviceState && (
            <DeviceConnected
              device={deviceState}
              onAttach={handleAttachDevice}
              isAttached={
                !!data?.devices.find(
                  (device) => device.name === deviceState.name,
                )
              }
            />
          )}
        </DeviceConnectContainer>
      </StyledDeviceContainer>
      {deviceState && (
        <PuffArray deviceId="c202ad67-ce5d-415f-be41-714d1b00ef50" />
      )}
    </>
  );
};

const StyledContainer = styled.div`
  display: flex;
`;

const DeviceConnectContainer = styled.div`
  color: white;
  display: flex;
  width: 50%;
  padding: 100px 200px;
  background-color: #25303d;
`;

const StyledDeviceContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 60px 40px;
`;

const StyledDeviceListContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
