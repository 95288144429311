import { IBluetoothConnectionFilter } from 'lib/services/interfaces';

export class DefaultBluetoothGateway {
  public async requestDevice(
    filters: IBluetoothConnectionFilter[],
    optionalServices?: string[],
  ): Promise<BluetoothDevice> {
    const bluetoothDevice = await navigator.bluetooth.requestDevice({
      filters,
      optionalServices,
    });

    if (!bluetoothDevice) {
      throw new Error('Failed to connect device');
    }

    return bluetoothDevice;
  }

  public async connectDevice(
    deviceName: string,
    optionalServices: string[],
  ): Promise<BluetoothDevice> {
    const bluetoothDevice = await navigator.bluetooth.requestDevice({
      filters: [{ name: deviceName }],
      optionalServices,
    });

    if (!bluetoothDevice) {
      throw new Error('Failed to connect device');
    }

    return bluetoothDevice;
  }

  public async getDevices(): Promise<BluetoothDevice[]> {
    const bluetoothDevices = await navigator.bluetooth.getDevices();
    return bluetoothDevices;
  }

  public async getDevice(deviceName: string): Promise<BluetoothDevice> {
    const bluetoothDevices = await navigator.bluetooth.getDevices();

    const bluetoothDevice = bluetoothDevices.find(
      (bluetoothDevice) => bluetoothDevice.name === deviceName,
    );

    if (!bluetoothDevice) {
      throw new Error('Device not found');
    }

    return bluetoothDevice;
  }
}
