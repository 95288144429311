import { COLORS, TRANSPARENCY_COLORS } from 'assets/styles/colors';
import { TYPOGRAPHY } from 'assets/styles/typography';
import styled, { css } from 'styled-components';
import { Icon } from 'ui';

const SIGNAL_LEVEL = {
  Great: 'great',
  Normal: 'normal',
  Bad: 'bad',
} as const;

enum LocalizeSignalLevel {
  great = 'Отличный',
  normal = 'Нормальный',
  bad = 'Плохой',
}
type SignalProps = {
  signalLevel?: number;
};

function calculateSignalLevel(num?: number) {
  let level = '';

  if (num) {
    if (num > -60) {
      level = SIGNAL_LEVEL.Great;
    } else if (num <= -60 && num > -85) {
      level = SIGNAL_LEVEL.Normal;
    } else {
      level = SIGNAL_LEVEL.Bad;
    }
  }

  return level;
}

export const Signal = ({ signalLevel }: SignalProps) => {
  const signalLevelText = calculateSignalLevel(
    signalLevel,
  ) as keyof typeof LocalizeSignalLevel;
  return (
    <>
      <SignalIconWrapper $signalLevel={signalLevelText}>
        <Icon type="signal" />
      </SignalIconWrapper>
      <Text>{LocalizeSignalLevel[signalLevelText]}</Text>
    </>
  );
};

const Text = styled.p`
  ${TYPOGRAPHY.body2}
  margin-left: 21px;
  color: ${COLORS.black};
`;

const SignalIconWrapper = styled.div<{
  $signalLevel: string;
}>`
  ${({ $signalLevel }) =>
    $signalLevel === SIGNAL_LEVEL.Normal
      ? css`
          & svg path:first-child {
            stroke: ${TRANSPARENCY_COLORS.blackThirty};
          }
        `
      : $signalLevel === SIGNAL_LEVEL.Bad
      ? css`
          & svg path:first-child {
            stroke: ${TRANSPARENCY_COLORS.blackThirty};
          }
          & svg path:nth-child(2) {
            stroke: ${TRANSPARENCY_COLORS.blackThirty};
          }
        `
      : ''}
`;
