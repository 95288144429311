import usePuffArrayState from 'lib/services/plonq/hooks/usePuffArrayState';
import { FC } from 'react';
import styled from 'styled-components';

interface Props {
  deviceId: string;
}

const PuffArray: FC<Props> = ({ deviceId }) => {
  const [puffArrayState, { isLoading }] = usePuffArrayState();

  if (isLoading) {
    return <div>Initializing</div>;
  }

  if (isLoading) {
    return <div>Synchronize</div>;
  }

  return (
    <PuffsContainer>
      <div>Puffs:</div>

      <ol>
        {puffArrayState.map((puff) => (
          <StyledPuffItem key={Math.random()}>
            <StyledPuffNote>{puff.timestamp}</StyledPuffNote>
            <StyledPuffNote>{puff.cartridgeId}</StyledPuffNote>
            <StyledPuffNote>{puff.puffTime} ms</StyledPuffNote>
            <StyledPuffNote>
              {(puff.nicotineLevel * 1000).toFixed(2)}
            </StyledPuffNote>
          </StyledPuffItem>
        ))}
      </ol>
    </PuffsContainer>
  );
};

const PuffsContainer = styled.div`
  margin: 20px 0;
`;

const StyledPuffItem = styled.div`
  display: flex;
  background-color: #ddd;
  padding-left: 5px;
`;

const StyledPuffNote = styled.div`
  margin: 0 40px;
`;

export default PuffArray;
