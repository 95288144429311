import { UserDeviceResponseDto } from 'api';
import { COLORS } from 'assets/styles/colors';
import { TYPOGRAPHY } from 'assets/styles/typography';
import { ReactComponent as EmptyListIcon } from 'assets/svg/empty-device-list-icon.svg';
import { DeviceCard, DeviceCardFormValues } from 'components';
import { ListItem } from 'components/ListItem/ListItem';
import { getMaskedPhoneNumber } from 'lib/helpers';
import { useBodyBackground } from 'lib/hooks';
import { FC, useState } from 'react';
import { useRenameDeviceMutation } from 'store/api/devices';
import styled from 'styled-components';
import { Button, Icon } from 'ui';
import { ConfirmModal } from 'ui/ConfirmModal';

interface DevicesProps {
  username?: string;
  phone?: string;
  isLoading?: boolean;
  devices?: UserDeviceResponseDto[];
  onNewDeviceAddClick: () => void;
  onDeviceClick: (id: string, name: string) => void;
  onLogoutClick: () => void;
  onDeleteDevice: (id: string) => void;
}

export const Devices: FC<DevicesProps> = ({
  username,
  phone,
  isLoading,
  devices,
  onNewDeviceAddClick,
  onDeleteDevice,
  onDeviceClick,
  onLogoutClick,
}) => {
  useBodyBackground(COLORS.lightViolet);
  const [renameDevice] = useRenameDeviceMutation();
  const [currentProcessableDeviceId, setCurrentProcessableDeviceId] =
    useState('');
  const [isDeleteDeviceModalOpen, setIsDeleteDeviceModalOpen] = useState(false);

  const isListEmpty = devices?.length === 0;
  const maskedPhone = getMaskedPhoneNumber(phone);

  const handleDeleteButtonClick = (id: string) => {
    setCurrentProcessableDeviceId(id);
    setIsDeleteDeviceModalOpen(true);
  };

  const handleDeleteDevice = () => {
    onDeleteDevice(currentProcessableDeviceId);
    setIsDeleteDeviceModalOpen(false);
  };

  const handleCancelDeleteModal = () => {
    setIsDeleteDeviceModalOpen(false);
    setCurrentProcessableDeviceId('');
  };

  const handleEditDevice = (deviceId: string, data: DeviceCardFormValues) => {
    renameDevice({ deviceId, alias: data.name });
  };

  return (
    <Root>
      <UserInfoContainer>
        <UserWrap>
          <Greetings>Привет, {username}!</Greetings>
          <TelNumber>{maskedPhone}</TelNumber>
        </UserWrap>
        <StyledIcon type="logout" onClick={onLogoutClick} />
      </UserInfoContainer>

      {isListEmpty ? (
        <EmptyListContainer>
          <EmptyListTitle>
            Здесь появятся Plonq X подключённые к вашему профилю.
          </EmptyListTitle>
          <EmptyListIcon />
        </EmptyListContainer>
      ) : (
        <ListContainer style={{ width: '100%' }}>
          <ListTitle>Мои Plonq X</ListTitle>

          {devices?.map((element) => (
            <DeviceCard
              element={element}
              key={element.id}
              onClick={onDeviceClick}
              onDelete={handleDeleteButtonClick}
              onEdit={handleEditDevice}
            />
          ))}
        </ListContainer>
      )}

      <StyledButton
        variant="secondary"
        isLoading={isLoading}
        isDisabled={isLoading}
        onClick={onNewDeviceAddClick}
        icon={<Icon type="plus" />}
      >
        Добавить новое устройство
      </StyledButton>

      <ConfirmModal
        text="Вы действительно хотите удалить устройство?"
        isOpen={isDeleteDeviceModalOpen}
        onYesCallback={handleDeleteDevice}
        closeCallback={handleCancelDeleteModal}
      />
    </Root>
  );
};

const Root = styled.div`
  background-color: ${COLORS.lightViolet};
  padding: 12px 21px 59px 21px;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: var(--100vh-height);
`;

const UserInfoContainer = styled.div`
  width: 100%;
  max-width: 372px;
  min-height: 90px;
  border-radius: 20px;
  background-color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 18px 23px 22px 29px;
`;

const UserWrap = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  padding-right: 7px;
`;

const Greetings = styled.p`
  overflow: hidden;
  overflow-wrap: anywhere;
  text-overflow: ellipsis;
  /* stylelint-disable value-no-vendor-prefix */
  /* stylelint-disable property-no-vendor-prefix */
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  ${TYPOGRAPHY.title1}
  color: ${COLORS.black};
`;

const TelNumber = styled.p`
  ${TYPOGRAPHY.subline1}
  color: ${COLORS.darkBlue};
`;

const StyledIcon = styled(Icon)`
  flex-shrink: 0;
  path {
    stroke: ${COLORS.lightBlue};
  }
`;

const EmptyListContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 43px;
  margin-top: 43px;
  margin-bottom: 44px;
`;

const EmptyListTitle = styled.p`
  color: ${COLORS.darkBlue};
  ${TYPOGRAPHY.body1}
  text-align: center;
  max-width: 271px;
`;

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 43px;
  margin-bottom: 44px;
  row-gap: 18px;
`;

const ListTitle = styled.p`
  color: ${COLORS.black};
  ${TYPOGRAPHY.title1}
  margin-bottom: 18px;
`;

const StyledButton = styled(Button)`
  margin-top: auto;
`;
