import { useCallback } from 'react';

export const useFirmwareDownload = () => {
  const downloadFirmware = useCallback(async (fileKey: string) => {
    const response = await fetch(fileKey);

    if (!response.ok) {
      throw new Error('Не удалось скачать файл');
    }

    const blob = await response.blob();

    return new File([blob], 'firmware.zip', { type: 'application/zip' });
  }, []);

  return downloadFirmware;
};
