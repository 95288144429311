import { COLORS } from 'assets/styles/colors';
import { TYPOGRAPHY } from 'assets/styles/typography';
import styled, { css } from 'styled-components';
import { Icon } from 'ui';

type BatteryProps = {
  batteryPercent?: number;
  isCharging?: boolean;
};

function calculateBatteryLevel(batteryChargePercentage?: number) {
  if (batteryChargePercentage) {
    switch (true) {
      case batteryChargePercentage >= 90:
        return 6;
      case batteryChargePercentage >= 75:
        return 5;
      case batteryChargePercentage >= 55:
        return 4;
      case batteryChargePercentage >= 40:
        return 3;
      case batteryChargePercentage >= 20:
        return 2;
      default:
        return 1;
    }
  } else {
    return 6;
  }
}

export const Battery = ({ batteryPercent, isCharging }: BatteryProps) => {
  return (
    <>
      {isCharging ? (
        <Icon type="batteryCharging" />
      ) : (
        <BatteryIconWrapper
          $batteryNumber={calculateBatteryLevel(batteryPercent)}
        >
          <Icon type="batteryFull" />
        </BatteryIconWrapper>
      )}
      <Text>{batteryPercent}%</Text>
    </>
  );
};

const Text = styled.p`
  ${TYPOGRAPHY.body2}
  margin-left: 21px;
  color: ${COLORS.black};
`;

const BatteryIconWrapper = styled.div<{ $batteryNumber: number }>`
  ${({ $batteryNumber }) => {
    switch ($batteryNumber) {
      case 1:
        return css`
          & svg path {
            fill: none;
          }
          & svg path:nth-last-child(6) {
            fill: ${COLORS.red};
          }
        `;
      case 2:
        return css`
          & svg path {
            fill: none;
          }
          & svg path:nth-last-child(6) {
            fill: ${COLORS.black};
          }
          & svg path:nth-last-child(5) {
            fill: ${COLORS.black};
          }
        `;
      case 3:
        return css`
          & svg path:nth-last-child(3) {
            fill: none;
          }
          & svg path:nth-last-child(2) {
            fill: none;
          }
          & svg path:nth-last-child(1) {
            fill: none;
          }
        `;
      case 4:
        return css`
          & svg path:nth-last-child(2) {
            fill: none;
          }
          & svg path:nth-last-child(1) {
            fill: none;
          }
        `;
      case 5:
        return css`
          & svg path:nth-last-child(1) {
            fill: none;
          }
        `;
      default:
        break;
    }
    return;
  }}
`;
