import { COLORS } from 'assets/styles/colors';
import usePlonqDeviceConnectedCheck from 'lib/services/plonq/hooks/usePlonqDeviceConnectedCheck';
import { DeviceIntro } from 'pages/DeviceDetail/components/DeviceIntro';
import { Statistics } from 'pages/DeviceDetail/components/Statistics';
import styled from 'styled-components';

export const DeviceDetail = () => {
  usePlonqDeviceConnectedCheck();

  return (
    <Root>
      <DeviceIntro />
      <StatisticsWrapper>
        <Statistics />
      </StatisticsWrapper>
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${COLORS.lightViolet};
  row-gap: 20px;
`;

const StatisticsWrapper = styled.div`
  margin-bottom: 24px;
`;
