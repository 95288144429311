import { COLORS } from 'assets/styles/colors';
import { TYPOGRAPHY } from 'assets/styles/typography';
import { ReactComponent as ConnectedIcon } from 'assets/svg/connected-icon.svg';
import { ReactComponent as NotConnectedIcon } from 'assets/svg/not-connected-icon.svg';
import { useComponentDidMount } from 'lib/hooks';
import { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Timer } from 'ui';

interface ConnectionProps {
  cartridgeId?: number;
  onTimerEnd: () => void;
  onOwnerConfirmed: () => void;
  onCancel: () => void;
}

export const Connection: FC<ConnectionProps> = ({
  cartridgeId,
  onCancel,
  onTimerEnd,
  onOwnerConfirmed,
}) => {
  const isComponentMounted = useComponentDidMount();
  const isCartridgeNotConnected = cartridgeId === 0;

  const [cartrigesConnections, setCartrigesConnections] = useState(0);
  const [cartrigesDisconnections, setCartrigesDisconnections] = useState(
    isCartridgeNotConnected ? 1 : 0,
  );
  const isOwnerConfirmed =
    Boolean(cartrigesConnections) && Boolean(cartrigesDisconnections);

  useEffect(() => {
    if (isComponentMounted) {
      if (isCartridgeNotConnected) {
        setCartrigesDisconnections((prevState) => prevState + 1);
      } else {
        setCartrigesConnections((prevState) => prevState + 1);
      }
    }
  }, [isCartridgeNotConnected]);

  useEffect(() => {
    if (isOwnerConfirmed) {
      onOwnerConfirmed();
    }
  }, [isOwnerConfirmed]);

  return (
    <Root>
      <ConnectionIconWrap>
        {isCartridgeNotConnected ? <NotConnectedIcon /> : <ConnectedIcon />}
      </ConnectionIconWrap>
      <TextContainer>
        <Text>
          Пожалуйста, <Bold>достаньте и вставьте</Bold> картридж в устройство в
          течение 30 секунд
        </Text>
        <Timer
          initialSeconds={30}
          isStart={true}
          onEndTimer={onTimerEnd}
          isOnlyBigSeconds
        />
        <ConnectLaterButton onClick={onCancel}>
          Подключиться позже
        </ConnectLaterButton>
      </TextContainer>
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 55px 37px 19px;
  max-width: 414px;
  margin: 0 auto;
`;

const ConnectionIconWrap = styled.div`
  margin-bottom: 54px;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 30px;
`;

const Text = styled.p`
  color: ${COLORS.black};
  ${TYPOGRAPHY.body2}
  text-align: center;
`;

const Bold = styled.span`
  font-weight: 500;
`;

const ConnectLaterButton = styled.button`
  color: ${COLORS.darkBlue};
`;

const SpinnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: var(--100vh-height);
`;
