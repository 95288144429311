import { useCallback } from 'react';

import { PlonqLocation } from '../plonq-location';

const useDeviceLocationSignal = () => {
  const location = useCallback(() => {
    PlonqLocation.getInstance().sendLocationSignal();
  }, []);

  return location;
};

export default useDeviceLocationSignal;
