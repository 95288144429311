import { FC } from 'react';
import styled from 'styled-components';

interface Props {
  device: {
    id: string;
    name: string;
    alias?: string;
    modelName: string;
  };
  onConnect: (name: string) => void;
  onRemove: (deviceId: string) => void;
  isLoading?: boolean;
}

const DeviceToConnect: FC<Props> = ({
  device,
  isLoading,
  onConnect,
  onRemove,
}) => {
  return (
    <DeviceContainer>
      <div>Device {device.modelName}:</div>
      <StyledDeviceToConnectInfo>
        <li>name: {device.name}</li>
        {device.alias && <li>alias: {device.alias}</li>}
      </StyledDeviceToConnectInfo>
      <StyledConnectButton
        disabled={isLoading}
        onClick={() => onConnect(device.name)}
      >
        Connect
      </StyledConnectButton>
      <StyledRemoveButton
        disabled={isLoading}
        onClick={() => onRemove(device.id)}
      >
        Remove
      </StyledRemoveButton>
    </DeviceContainer>
  );
};

const DeviceContainer = styled.div`
  margin: 20px 0;
`;

const StyledDeviceToConnectInfo = styled.ul`
  padding-left: 20px;
`;

const StyledConnectButton = styled.button`
  margin: 10px;
  padding: 5px;
  border-radius: 5px;
  background-color: blue;
  color: white;
`;

const StyledRemoveButton = styled.button`
  margin: 10px;
  padding: 5px;
  border-radius: 5px;
  background-color: red;
  color: white;
`;

export default DeviceToConnect;
