import { useAuth } from 'lib/hooks';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

import { ROUTES } from './route-paths';

export function PublicOutlet() {
  const token = useAuth();
  const location = useLocation();

  if (!navigator?.bluetooth) {
    return <Navigate to={ROUTES.BROWSER_CHECK} state={{ from: location }} />;
  }

  if (token) {
    return <Navigate to={ROUTES.ROOT} state={{ from: location }} />;
  }

  return <Outlet />;
}
