import { getCartridgeById } from 'lib/helpers/getCartridgeById';

import { ICartridge } from '../interfaces';
import { BLEConnectionNotInstalled } from './exception/ble-connection-not-installed';
import { PlonqDeviceConnection } from './plonq-device-connection';

type UpdateHandler = (device: PlonqDevice) => void;

export class PlonqDevice {
  private name?: string;
  private modelName?: string;
  private macAddress?: string;
  private cartridgeId?: number;
  private firmwareVersion?: string;
  private hardwareVersion?: string;
  private batteryLevel?: number;
  private isCharging?: boolean;
  private rssi?: number;
  private isBleConnected?: boolean;
  private bleConnection?: PlonqDeviceConnection;
  private timestampOffset = 0;

  private updateHandlers: UpdateHandler[] = [];

  private static instance?: PlonqDevice;

  public setBleConnection(bleConnection: PlonqDeviceConnection): void {
    this.bleConnection = bleConnection;
    this.isBleConnected = true;
  }

  public checkIsBleConnected(): boolean {
    return !!this.isBleConnected;
  }

  public static getInstance(): PlonqDevice {
    if (this.instance) {
      return this.instance;
    }
    this.instance = new PlonqDevice();
    return this.instance;
  }

  public getBleConnection(): PlonqDeviceConnection | undefined {
    return this.bleConnection;
  }

  public getName(): string {
    if (!this.name) {
      throw new Error('Device not initialized');
    }

    return this.name;
  }

  public getModelName(): string {
    if (!this.modelName) {
      throw new Error('Device not initialized');
    }

    return this.modelName;
  }

  public getMacAddress(): string {
    if (!this.macAddress) {
      throw new Error('Device not initialized');
    }

    return this.macAddress;
  }

  public getFirmware(): string {
    if (!this.firmwareVersion) {
      throw new Error('Device not initialized');
    }

    return this.firmwareVersion;
  }

  public getHardwareVersion(): string {
    if (!this.hardwareVersion) {
      throw new Error('Device not initialized');
    }

    return this.hardwareVersion;
  }

  public getCartridgeId(): number {
    if (this.cartridgeId === undefined) {
      throw new Error('Device not initialized');
    }

    return this.cartridgeId;
  }

  public getCartridge(): ICartridge | undefined {
    if (this.cartridgeId === undefined) {
      throw new Error('Device not initialized');
    }

    return getCartridgeById(this.cartridgeId);
  }

  public getBatteryLevel(): number {
    if (this.batteryLevel === undefined) {
      throw new Error('Device not initialized');
    }

    return this.batteryLevel;
  }

  public getIsCharging(): boolean {
    if (this.isCharging === undefined) {
      throw new Error('Device not initialized');
    }

    return this.isCharging;
  }

  public getRSSI(): number {
    if (this.rssi === undefined) {
      throw new Error('Device not initialized');
    }

    return this.rssi;
  }

  public getTimestampOffset(): number {
    return this.timestampOffset;
  }

  public subscribeOnUpdates(addingHandler: UpdateHandler): void {
    this.updateHandlers.push(addingHandler);
  }

  public unsubscribeFromUpdate(removingHandler: UpdateHandler): void {
    this.updateHandlers = this.updateHandlers.filter(
      (handler) => handler !== removingHandler,
    );
  }

  private dispatchUpdate(): void {
    for (const listener of this.updateHandlers) listener(this);
  }

  private onCartridgeIdChange(cartridgeId: number): void {
    this.cartridgeId = cartridgeId;
    this.dispatchUpdate();
  }

  private onChargingChange(isCharging: boolean): void {
    this.isCharging = isCharging;
    this.dispatchUpdate();
  }

  private onBatteryLevelChange(batteryLevel: number): void {
    this.batteryLevel = batteryLevel;
    this.dispatchUpdate();
  }

  private onRSSIChange(rssi: number): void {
    this.rssi = rssi;
    this.dispatchUpdate();
  }

  public disconnect = (): void => {
    if (this.isBleConnected === undefined) {
      throw new Error('BLE connection not installed');
    }

    this.bleConnection?.disconnect();
    this.clearDeviceData();
  };

  public clearDeviceData = (): void => {
    PlonqDevice.instance = undefined;
    this.isBleConnected = false;
    this.bleConnection = undefined;
    this.name = undefined;
    this.modelName = undefined;
    this.macAddress = undefined;
    this.firmwareVersion = undefined;
    this.hardwareVersion = undefined;
    this.batteryLevel = undefined;
    this.cartridgeId = undefined;
    this.isCharging = undefined;
    this.rssi = undefined;
    this.dispatchUpdate();
    this.updateHandlers = [];
  };

  public async syncWithBLE(name: string) {
    if (!this.bleConnection) {
      throw new BLEConnectionNotInstalled();
    }

    this.bleConnection.onDisconnect(this.clearDeviceData.bind(this));
    this.bleConnection.onReconnect(this.subscribeChanges.bind(this));

    this.name = name;
    this.modelName = await this.bleConnection.getModelName();
    this.macAddress = await this.bleConnection.getMacAddress();
    this.firmwareVersion = await this.bleConnection.getFirmwareVersion();
    this.hardwareVersion = await this.bleConnection.getHardwareVersion();
    this.batteryLevel = await this.bleConnection.getBatteryLevel();
    this.cartridgeId = await this.bleConnection.getCartridgeId();
    this.isCharging = await this.bleConnection.getIsCharging();
    this.rssi = await this.bleConnection.getRSSI();

    this.timestampOffset =
      new Date().getTime() - (await this.bleConnection.getUnixTime()) * 1000;

    await this.subscribeChanges();
  }

  private async subscribeChanges(): Promise<void> {
    if (!this.bleConnection) {
      throw new BLEConnectionNotInstalled();
    }

    await this.bleConnection.onRSSIChange(this.onRSSIChange.bind(this));
    await this.bleConnection.onBatteryLevelChange(
      this.onBatteryLevelChange.bind(this),
    );
    await this.bleConnection.onChargingChange(this.onChargingChange.bind(this));
    await this.bleConnection.onCartridgeIdChange(
      this.onCartridgeIdChange.bind(this),
    );
  }
}
