import { UserDeviceResponseDto } from 'api';
import { COLORS } from 'assets/styles/colors';
import { TYPOGRAPHY } from 'assets/styles/typography';
import { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSwipeable } from 'react-swipeable';
import styled, { css } from 'styled-components';
import { Icon } from 'ui';

import { formatDate } from './formatDate';

export interface DeviceCardFormValues {
  name: string;
}

export interface DeviceCardProps {
  element: UserDeviceResponseDto;
  onClick: (id: string, name: string) => void;
  onDelete: (id: string) => void;
  onEdit?: (deviceId: string, data: DeviceCardFormValues) => void;
}

export const DeviceCard: FC<DeviceCardProps> = ({
  element,
  onDelete,
  onEdit,
  onClick,
}) => {
  const [isShifted, setIsShifted] = useState(false);
  const swipeableProps = useSwipeable({
    onSwipedLeft: () => {
      setIsShifted(true);
    },
    onSwipedRight: () => {
      setIsShifted(false);
    },
  });
  const [isEditable, setIsEditable] = useState(false);
  const { name, alias, modelName, lastConnectionDate } = element;

  const { handleSubmit, register, reset } = useForm<DeviceCardFormValues>();

  const onSubmit = (data: DeviceCardFormValues) => {
    onEdit?.(element.id, data);
    setIsEditable(!isEditable);
  };

  const lastConnectionDateText = formatDate(lastConnectionDate);

  return (
    <Wrapper>
      <Root
        $isShifted={isShifted}
        {...swipeableProps}
        $isEditable={isEditable}
        onClick={
          isEditable ? undefined : () => onClick(element.id, element.name)
        }
      >
        <Logo>
          <Icon type="logo" />
        </Logo>
        <Container>
          <Top>
            {!isEditable ? (
              <>
                <Row>
                  <Name>{alias ?? modelName}</Name>
                  <button
                    onClick={(event) => {
                      event.stopPropagation();
                      setIsEditable(!isEditable);
                    }}
                  >
                    <Icon type="editWithLine" />
                  </button>
                </Row>
                {lastConnectionDate && (
                  <LastConnectionDate dateTime={lastConnectionDate}>
                    {lastConnectionDateText}
                  </LastConnectionDate>
                )}
              </>
            ) : (
              <InputWrapper>
                <Input
                  type="text"
                  minLength={1}
                  maxLength={20}
                  {...register('name', {
                    minLength: 1,
                    maxLength: 20,
                  })}
                />
                <InputButtons>
                  <SubmitBtn onClick={handleSubmit(onSubmit)}>
                    <Icon type="checkInCircle2" />
                  </SubmitBtn>
                  <CloseBtn
                    type="button"
                    onClick={() => {
                      setIsEditable(!isEditable);
                      reset();
                    }}
                  >
                    <Icon type="questionClose" />
                  </CloseBtn>
                </InputButtons>
              </InputWrapper>
            )}
          </Top>
          <ModelName>{name}</ModelName>
        </Container>
      </Root>
      <DeleteBtn
        $isShifted={isShifted}
        onClick={() => {
          onDelete(element.id);
          setIsShifted(false);
        }}
      >
        Удалить
      </DeleteBtn>
    </Wrapper>
  );
};

interface RootStyleProps {
  $isEditable: boolean;
  $isShifted: boolean;
}

const Wrapper = styled.div`
  width: 100%;
  max-width: 314px;
  display: flex;
  position: relative;
  justify-content: center;
`;

const Root = styled.div<RootStyleProps>`
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 331px;
  border-radius: 15px;
  background-color: ${COLORS.white};

  ${({ $isEditable }) =>
    $isEditable
      ? css`
          padding: 8px 15px 9px 18px;
        `
      : css`
          padding: 11px 15px 13px 18px;
        `};

  &:hover {
    filter: drop-shadow(3px 3px 6px rgba(120, 114, 152, 0.1));
  }

  transform: ${({ $isShifted }) => ($isShifted ? 'translateX(-114px)' : '')};
  transition: transform 0.1s linear;
`;

const Logo = styled.button`
  margin-right: 18px;
`;

const Container = styled.div`
  width: 100%;
`;

const Name = styled.h3`
  ${TYPOGRAPHY.buttons}
  color: ${COLORS.black};
  margin-right: 10px;
`;

const ModelName = styled.span`
  ${TYPOGRAPHY.subline1}
  line-height: 0;
  color: ${COLORS.black};
  opacity: 0.5;
`;

const Top = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
`;

const LastConnectionDate = styled.time`
  ${TYPOGRAPHY.subline1}
  color: ${COLORS.black};
  opacity: 0.5;
`;

const InputWrapper = styled.div`
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3px 8px;
  position: relative;

  border: 1px solid ${COLORS.lightViolet};
  background-color: ${COLORS.white};
  width: 100%;
`;

const Input = styled.input`
  width: calc(100% - 65px);
  ${TYPOGRAPHY.body1}
  color: ${COLORS.black};
`;

const InputButtons = styled.div`
  position: absolute;
  top: -4px;
  right: 0;
  z-index: 1;
`;

const SubmitBtn = styled.button`
  position: relative;
  left: 7px;
`;

const CloseBtn = styled.button`
  & > svg {
    stroke: ${COLORS.red};
  }
`;

const DeleteBtn = styled.button<{ $isShifted: boolean }>`
  background: ${COLORS.white};
  color: ${COLORS.red};
  width: ${({ $isShifted }) => ($isShifted ? '104px' : '0px')};
  position: absolute;
  right: 0px;
  bottom: 0;
  top: 0;
  transition: width 0.1s linear;
  overflow: hidden;
  border-radius: 15px;
`;
