import { IOverpuffSettings } from 'lib/services/interfaces';
import { useCallback, useEffect, useState } from 'react';

import { BLEConnectionNotInstalled } from '../exception/ble-connection-not-installed';
import { PlonqOverpuff } from '../plonq-overpuff';

const useDeviceOverpuffState = () => {
  const [overpuffRef, setOverpuffRef] = useState<PlonqOverpuff>();
  const [overpuff, setOverpuff] = useState<IOverpuffSettings>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const initOverpuffConnection = async () => {
    setIsLoading(() => true);
    const overpuff = PlonqOverpuff.getInstance();

    await overpuff.syncWithBLE();

    setOverpuffRef(overpuff);
    setOverpuff(overpuff.getSettings());
    setIsLoading(() => false);
  };

  const onPlonqOverpuffUpdate = useCallback((plonqOverpuff: PlonqOverpuff) => {
    setOverpuff(() => plonqOverpuff.getSettings());
  }, []);

  const setSettings = useCallback(
    (newSettings: IOverpuffSettings) => {
      if (!overpuffRef) {
        throw new BLEConnectionNotInstalled();
      }

      console.log(newSettings);
      return overpuffRef.setSettings(newSettings);
    },
    [overpuffRef],
  );

  useEffect(() => {
    initOverpuffConnection();
  }, []);

  useEffect(() => {
    if (overpuffRef) {
      overpuffRef.subscribeOnUpdates(onPlonqOverpuffUpdate);
    }

    return () => {
      overpuffRef?.unsubscribeFromUpdate(onPlonqOverpuffUpdate);
    };
  }, [overpuffRef, onPlonqOverpuffUpdate]);

  return [overpuff, { isLoading, setSettings }] as const;
};

export default useDeviceOverpuffState;
