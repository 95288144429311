const GUEST_PATHS = {
  LOGIN: '/login',
  REGISTRATION: '/registration',
  RESTORE_PASSWORD: '/restore-password',
  AGE_CHECK: '/age-check',
  AGE_LIMIT: '/age-limit',
  INTRO: '/intro',
  BROWSER_CHECK: '/browser-check',
};

const AUTH_PATHS = {
  ROOT: '/',
  DEVICE: '/device',
  DEVICE_ID: (id: string) => `/device/${id}`,
  UPDATE: '/update',
  UPDATED_DEVICE_ID: (id: string) => `/update/${id}`,
  UPDATE_SUCCESS: '/update/update-success',
};

export const ROUTES = {
  ...GUEST_PATHS,
  ...AUTH_PATHS,
  EXAMPLE: '/example',
};
