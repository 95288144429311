import { useInterval } from 'lib/hooks/useInterval';
import * as _ from 'lodash';
import {
  useLazyGetLastSavedPuffQuery,
  usePostStatisticsMutation,
} from 'store/api/statistics';
import { PowerSettings } from 'types';

import usePuffArrayState from './usePuffArrayState';

const usePuffArrayUpload = (deviceId: string) => {
  const [
    puffArrayState,
    { isLoading: isPuffArrayInitializing, clearPuffArray },
  ] = usePuffArrayState();

  const [postStatistics, { isLoading: isSynchronize }] =
    usePostStatisticsMutation();

  const [getLastPuff, { isFetching: isLastPuffFetching }] =
    useLazyGetLastSavedPuffQuery();

  const handlePostStatistics = async () => {
    if (!puffArrayState.length) {
      return;
    }

    console.log(puffArrayState);

    const lastPuff = await getLastPuff({ deviceId }).unwrap();

    console.log('last actual puff');
    console.log(lastPuff);

    let newPuffs = [];

    if (lastPuff) {
      const lastPuffTimestamp = new Date(lastPuff.date).getTime();
      newPuffs = puffArrayState.filter(
        (puff) => puff.timestamp > lastPuffTimestamp,
      );
    } else {
      newPuffs = puffArrayState;
    }

    console.log('new puff list');
    console.log(newPuffs);

    for (const chunk of _.chunk(newPuffs, 100)) {
      await postStatistics({
        puffLength: chunk.length,
        deviceId,
        puffs: chunk.map((puff) => ({
          timestamp: new Date(puff.timestamp).toISOString(),
          puffTime: puff.puffTime,
          powerSettings: PowerSettings.MEDIUM,
          cartridgeFactoryId: puff.cartridgeId,
          nicotineLevel: puff.nicotineLevel,
        })),
      }).unwrap();
    }

    await clearPuffArray();
  };

  useInterval(
    () => !isSynchronize && !isLastPuffFetching && handlePostStatistics(),
    3000,
  );

  return [
    puffArrayState,
    {
      isSynchronize: isSynchronize || isLastPuffFetching,
      isInitializing: isPuffArrayInitializing,
    },
  ] as const;
};

export default usePuffArrayUpload;
