import { IDevice } from 'lib/services/interfaces';
import { FC } from 'react';
import styled from 'styled-components';

interface Props {
  device: IDevice;
  isAttached: boolean;
  isLoading?: boolean;
  onAttach: () => void;
}

const DeviceConnected: FC<Props> = ({
  device,
  isAttached,
  isLoading,
  onAttach,
}) => {
  return (
    <StyledDeviceConnectedContainer>
      {isLoading ? (
        <div>...Loading</div>
      ) : (
        <>
          <div>Device {device.modelName}:</div>
          <StyledDeviceInfo>
            <li>Bluetooth Name: {device.name}</li>
            <li>MAC: {device.macAddress}</li>
            <li>Firmware Ver.: {device.firmwareVersion}</li>
            <li>Hardware Ver.: {device.hardwareVersion}</li>
            <li>Cartridge Id: {device.cartridgeId}</li>
            <li>Battery: {device.batteryLevel}</li>
            <li>RSSI: {device.rssi}</li>
            <li>Charging: {device.isCharging ? 'Yes' : 'No'}</li>
          </StyledDeviceInfo>
          {!isAttached && (
            <StyledAttachButton onClick={() => onAttach()}>
              Attach
            </StyledAttachButton>
          )}
        </>
      )}
    </StyledDeviceConnectedContainer>
  );
};

export default DeviceConnected;

const StyledDeviceConnectedContainer = styled.div`
  padding: 20px;
  border-radius: 5px;
  color: #3abbff;
  background-color: #18212e;
`;

const StyledDeviceInfo = styled.ul`
  padding: 20px;
`;

const StyledAttachButton = styled.button`
  margin: 10px;
  padding: 5px;
  border-radius: 5px;
  background-color: blue;
  color: white;
`;
