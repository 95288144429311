import diviceImage from 'assets/images/device-image.png';
import { COLORS } from 'assets/styles/colors';
import { DEVICE, SIZE } from 'assets/styles/devices';
import { TYPOGRAPHY } from 'assets/styles/typography';
import border from 'assets/svg/gradient-border.svg';
import { getCartridgeById } from 'lib/helpers/getCartridgeById';
import useDeviceState from 'lib/services/plonq/hooks/useDeviceState';
import { PlonqDevice } from 'lib/services/plonq/plonq-device';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ROUTES } from 'routes/route-paths';
import { useGetDeviceListQuery } from 'store/api/devices';
import styled from 'styled-components';
import { FindDeviceModal, Icon, Logo, Spinner } from 'ui';
import { ConfirmModal } from 'ui/ConfirmModal';
import { Menu } from 'ui/Menu';

import { Battery } from './components/Battery/Battery';
import { Signal } from './components/Signal/Signal';

export const DeviceIntro = () => {
  const { deviceId } = useParams();
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isFindModalOpen, setIsFindModalOpen] = useState(false);
  const [isCloseDeviceModalOpen, setIsCloseDeviceModalOpen] = useState(false);
  const [deviceState, setDeviceState] = useDeviceState();

  const { data: dataDevices, isLoading: isDeviceListLoading } =
    useGetDeviceListQuery();

  const currentDeviceFromList = useMemo(
    () => dataDevices?.devices.find((device) => device.id === deviceId),
    [dataDevices],
  );

  const currentCartridge = getCartridgeById(deviceState?.cartridgeId);
  const isPageLoading = isDeviceListLoading || !deviceState;

  const handleCloseDevicePage = () => {
    setIsCloseDeviceModalOpen(false);

    PlonqDevice.getInstance().disconnect();
    navigate(ROUTES.ROOT);
  };

  useEffect(() => {
    if (!deviceState) {
      const deviceInstance = PlonqDevice.getInstance();

      if (deviceInstance?.checkIsBleConnected()) {
        setDeviceState(deviceInstance);
      } else {
        navigate(ROUTES.ROOT);
      }
    }
  }, []);

  if (isPageLoading) {
    return (
      <SpinnerWrapper>
        <Spinner />
      </SpinnerWrapper>
    );
  }

  return (
    <Root>
      <Header>
        <Logo width="135px" />
        <button onClick={() => setIsMenuOpen(true)}>
          <Icon type="burger" />
        </button>
      </Header>
      <ContentWrapper>
        <InfoColumn>
          <InfoTextContainer>
            <Signal signalLevel={deviceState?.rssi} />
          </InfoTextContainer>
          <InfoTextContainer>
            <Battery
              batteryPercent={deviceState?.batteryLevel}
              isCharging={deviceState?.isCharging}
            />
          </InfoTextContainer>
          <InfoTextContainer>
            <Icon type="flavor" />
            <Text>{currentCartridge?.readableRuName || 'Не подключено'}</Text>
          </InfoTextContainer>
          <FindButton onClick={() => setIsFindModalOpen(true)}>
            <Icon type="findButton" /> Найти Plonq X
          </FindButton>
        </InfoColumn>
        <Image src={diviceImage} />
      </ContentWrapper>

      {currentDeviceFromList && deviceId && isMenuOpen && (
        <Menu
          isOpen={isMenuOpen}
          closeCallBack={() => setIsMenuOpen(false)}
          onExitClick={() => setIsCloseDeviceModalOpen(true)}
          onAddNewPlonqClick={handleCloseDevicePage}
          deviceData={{
            deviceId,
            activatedAt: currentDeviceFromList.activatedAt,
            deviceVersion: deviceState?.hardwareVersion,
            firmwareVersion: deviceState?.firmwareVersion,
            MACAddress: deviceState?.macAddress,
          }}
        />
      )}

      {isFindModalOpen && (
        <FindDeviceModal
          isOpen={isFindModalOpen}
          closeCallback={() => setIsFindModalOpen(false)}
        />
      )}

      <ConfirmModal
        text="Вы точно хотите выйти со страницы устройства?"
        isOpen={isCloseDeviceModalOpen}
        onYesCallback={handleCloseDevicePage}
        closeCallback={() => setIsCloseDeviceModalOpen(false)}
      />
    </Root>
  );
};

const Root = styled.div`
  background: linear-gradient(
    180deg,
    rgba(242, 241, 246, 0.8) 0,
    rgba(210, 212, 218, 0.8) 67.19%
  );
  border-bottom-left-radius: 50px;
  border-bottom-right-radius: 50px;
  width: 100%;
  max-width: 414px;
  margin: 0 auto;
  height: 514px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 27px 22px 0px 32px;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 25px 10px 0 10px;

  @media ${DEVICE.mobileM} {
    padding: 27px 16px 0 32px;
  }
`;

const Image = styled.img`
  flex-grow: 2;
  max-width: 159px;
  height: 380px;
`;

const InfoColumn = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 67px;
  row-gap: 31px;
  flex-grow: 1;
`;

const InfoTextContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 14px;
`;

const Text = styled.p`
  ${TYPOGRAPHY.body2}
  margin-left: 16px;
  color: ${COLORS.black};
`;

const FindButton = styled.button`
  color: ${COLORS.black};
  ${TYPOGRAPHY.body2}
  border-radius: 22px;
  background-image: url(${border});
  width: 100%;
  max-width: 174px;
  height: 45px;
  background-repeat: no-repeat;
  margin-top: 70px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;

  @media (max-width: ${SIZE.mobileM}) {
    font-size: 14px;
    letter-spacing: -0.56px;
  }
`;

const SpinnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: var(--100vh-height);
`;
